<template>
  <div class="error-wrapper">
    <div class="center-wrapper">
      <div class="text">401</div>
      <div class="desc">对不起，您无权限进入此页面</div>
      <img src="@/assets/error/noright.png" alt="" />
      <div class="back-to-dshaboard" @click="backToDshaboard">返回</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "noPermission",
  methods: {
    backToDshaboard() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
<style lang="scss" scoped>
.error-wrapper {
  width: 100%;
  height: 100%;
  font-size: 10em;
  font-weight: bolder;
  text-align: center;
  color: #ccecff;
  background-color: #41416e;
  .center-wrapper {
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .text {
      color: #ccecff;
      font-size: 1em;
    }
    .desc {
      font-size: 14px;
      color: #ccecff;
    }
    .back-to-dshaboard {
      width: 140px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 14px;
      border-radius: 20px;
      color: #ffffff;
      margin: 0 auto;
      border: 1px solid #ffffff;
      cursor: pointer;
    }
  }
}
</style>
